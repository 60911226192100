<!--
  删除主页弹框
-->
<template>
  <!-- 删除主页 -->
  <el-dialog
    :visible="deleteCustomTo"
    :title="$t('label_tabpage_delsuccessz')"
    :before-close="handleClose"
    :close-on-click-modal="false"
  >
    <div class="dialog-body">
      <div>
        <p>
          <!-- 确定要删除 -->
          {{ $t("message.delete") }}
          "{{ name }}"？
        </p>
        <p>{{ $t("vue_label_norm_undone") }}</p>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="deleteCustomCancel">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="deleteCustomConfirm">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    deleteCustomTo: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    handleClose() {
      this.$emit("deleteCustomCancel");
    },
    deleteCustomCancel() {
      this.$emit("deleteCustomCancel");
    },
    deleteCustomConfirm() {
      this.$emit("deleteCustomConfirm");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
  // height: 390px;
}
.dialog-body {
  div {
    p:first-child {
      font-size: 16px;
      color: #686868;
    }
    p:last-child {
      color: #ff3c32;
      font-size: 12px;
    }
  }
}
</style>