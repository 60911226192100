<!--
  简档主页分配弹框
-->
<template>
  <!-- 简档主页分配 -->
  <el-dialog
    :visible="pageLayoutAllocationTo"
    :title="$t('vue_label_homepage_profilehomepageassignment')"
    :before-close="cancleForm"
    :close-on-click-modal="false"
    width="750px"
  >
    <div class="center_center">
      <div class="top_word">
        <!-- 为不同的简档分配不同的主页 -->
        {{ $t("vue_label_homepage_differentprofiles") }}
      </div>
      <el-form label-width="" class="demo-ruleForm">
        <!-- 下拉框 -->
        <el-form-item
          :label="item.profilename"
          v-for="(item, index) in pageLayoutData"
          :key="index"
        >
          <el-select
            v-model="item.homePageId"
            placeholder
            clearable
            filterable
            @change="changeData"
          >
            <el-option
              v-for="(item1, index1) in tableData"
              :key="index1"
              :label="item1.name"
              :value="item1.id"
            ></el-option>
          </el-select>
          <!-- 设为默认主页 -->
          <el-checkbox
            :label="$t('vue_label_homepage_setdefault')"
            v-model="item.checked"
            @change="setMessage(item.homePageId)"
            style="margin-right:22px"
          ></el-checkbox>
          <!-- 应用于移动端 -->
          <el-checkbox
            :label="$t('label.tab.profile.ismobile')"
            v-model="item.mobileChecked"
            @change="setMobile(item.homePageId)"
          ></el-checkbox>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancleForm">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="submitForm">
        <!-- 确认 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as request from "../api.js";
export default {
  props: {
    pageLayoutAllocationTo: {
      // 控制弹框显示隐藏
      type: Boolean,
      default: false,
    },
    pageLayoutData: {
      type: Array,
      default: () => [],
    },
    homeProfileList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {};
  },
  watch: {
    homeProfileList: function () {
      this.homeProfileList.map((item) => {
        this.pageLayoutData.map((item1) => {
          if (item1.id === item.profileid) {
            item1.homePageId = item.homepageid;
            item1.checked = JSON.parse(item.isdefault);
            item1.mobileChecked = JSON.parse(item.ismobile);
          }
        });
      });
    },
  },
  methods: {
    changeData() {
      this.$forceUpdate();
    },
    //提交
    async submitForm() {
      let data = [];
      // 在角色列表中筛选出已选择主页的数据
      this.pageLayoutData.map((item) => {
        if (item.homePageId !== "") {
          data.push({
            homePageId: item.homePageId,
            profileId: item.id,
            isdefault: String(item.checked), //是否 设为默认主页
            isMobile: String(item.mobileChecked), //是否应用于移动端
          });
        }
      });
      let params = {
        type: "profile",
        dataVals: JSON.stringify(data),
      };
      await request.saveHomePageProfile(params).catch(() => {});
      this.$emit("cancleForm");
      // 主页分配成功！
      this.$message.success(
        this.$i18n.t("vue_label_homepage_homepage_assigned_success")
      );
    },
    //取消
    cancleForm() {
      this.$emit("cancleForm");
    },
    //设为默认主页
    setMessage(homePageId) {
      this.$forceUpdate();
      if (homePageId === "") {
        this.$message.info(this.$i18n.t("vue_label_homepage_notice_cantset")); //未给该简档配置简档主页，无法修改简档的默认主页
      }
    },
    // 应用于移动端
    setMobile(homePageId) {
      this.$forceUpdate();
      if (homePageId === "") {
        this.$message.info(
          this.$i18n.t("label.h5.homepage.cannot.applied.mobile.terminal")
        ); //未给该简档配置简档主页，无法应用于移动端
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 320px;
}
.center_center {
  .top_word {
    font-size: 12px;
    color: #080707;
  }
}
::v-deep .el-form {
  .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    .el-form-item__label {
      line-height: 30px;
      height: 30px;
      font-size: 12px;
      color: #080707;
    }
    .el-form-item__content {
      height: 30px;
      line-height: 30px;
      .el-select {
        width: 273px;
        height: 30px;
        .el-input {
          height: 30px;
          line-height: 30px;
          .el-input__icon {
            line-height: 30px;
          }
        }
        .el-input__inner {
          height: 30px;
          line-height: 30px;
        }
      }
    }
  }
}
.el-checkbox {
  margin-left: 20px;
  margin-top: 4px;
}
</style>