<!--
  应用程序主页分配弹框
-->
<template>
  <!-- 应用程序主页 -->
  <el-dialog
    :visible="applicationHomePageTo"
    :title="$t('vue_label_homepage_application_page')"
    :before-close="cancleApplicationHomePage"
    :close-on-click-modal="false"
    width="500px"
  >
    <div class="center_center">
      <div class="top_word">
        <!-- 用户使用特定应用程序时的主页，这些分配会覆盖标准主页 -->
        {{ $t("vue_label_homepage_user_particular_application") }}
      </div>
      <!-- {{pageLayoutData}} -->
      <el-form label-width="" class="demo-ruleForm">
        <el-form-item
          :label="item.applabel"
          v-for="(item, index) in pageLayoutData"
          :key="index"
        >
          <el-select
            v-model="item.homePageId"
            placeholder
            @change="changeData"
            clearable
            filterable
            :disabled="item.iscustom === '0'"
          >
            <el-option
              v-for="(item1, index1) in tableData"
              :key="index1"
              :label="item1.name"
              :value="item1.id"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancleApplicationHomePage">
        <!-- 取消 -->
        {{ $t("button_partner_cancel") }}
      </el-button>
      <el-button type="primary" @click="submitForm">
        <!-- 确认 -->
        {{ $t("label.ems.confirm") }}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
import * as request from "../api.js";
export default {
  props: {
    applicationHomePageTo: {
      // 控制弹框显示隐藏
      type: Boolean,
      default: false,
    },
    pageLayoutData: {
      type: Array,
      default: () => [],
    },
    homeProfileList: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    // 应用程序id
    // applicationId: {
    //   type: String,
    //   default: "",
    // },
  },
  data() {
    return {};
  },
  watch: {
    homeProfileList: function () {
      this.homeProfileList.map((item) => {
        this.pageLayoutData.map((item1) => {
          if (item1.id === item.profileid) {
            item1.homePageId = item.homepageid;
          }
        });
      });
    },
  },
  methods: {
    changeData() {
      this.$forceUpdate();
    },
    //提交
    async submitForm() {
      let data = [];
      // 在角色列表中筛选出已选择主页的数据
      this.pageLayoutData.map((item) => {
        if (item.homePageId !== "") {
          data.push({
            homePageId: item.homePageId, //主页页面id
            profileId: item.id, //应用程序id
          });
        }
      });
      let params = {
        // id: this.applicationId, //应用程序id
        type: "app",
        dataVals: JSON.stringify(data),
      };
      await request.saveHomePageProfile(params).catch(() => {});
      this.$emit("cancleApplicationHomePage");
      // 主页分配成功！
      this.$message.success(
        this.$i18n.t("vue_label_homepage_homepage_assigned_success")
      );
    },
    //取消
    cancleApplicationHomePage() {
      this.$emit("cancleApplicationHomePage");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__body {
  height: 320px;
}
.center_center {
  .top_word {
    font-size: 12px;
    color: #080707;
  }
}
::v-deep .el-form {
  .el-form-item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 10px;
    .el-form-item__label {
      line-height: 30px;
      height: 30px;
      font-size: 12px;
      color: #080707;
    }
    .el-form-item__content {
      height: 30px;
      line-height: 30px;
      .el-select {
        width: 273px;
        height: 30px;
        .el-input {
          height: 30px;
          line-height: 30px;
          .el-input__icon {
            line-height: 30px;
          }
        }
        .el-input__inner {
          height: 30px !important;
          line-height: 30px;
        }
      }
    }
  }
}
</style>