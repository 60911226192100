<!--
  复制主页（暂未使用）
-->
<template>
  <div class="copyCustom">
    <div class="copyCustom_center">
      <div class="center_header">
        <div class="header_left">复制主页</div>
        <div class="header_right" @click="copyCustomCancel">
        </div>
      </div>
      <div class="center_center">
        <el-form ref="form" :model="form" :rules="rules">
          <!-- 主页名称 -->
          <el-form-item
            :label="$t('vue_label_homepage_name')"
            prop="copyLabel"
            :label-width="formLabelWidth"
          >
            <el-input v-model="form.copyLabel"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <div class="center_footer">
        <div @click="copyCustomCancel">
          {{ $t("button_partner_cancel") }}
        </div>
        <div @click="copyCustomConfirm('form')">
          {{ $t("label.ems.confirm") }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    nameCopy: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      form: {
        copyLabel: "",
      },
      formLabelWidth: "100px",
      rules: {
        copyLabel: [
          // 请输入主页名称
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_homepage_pleaceentername"),
            trigger: "blur",
          },
        ],
      },
    };
  },
  watch: {
    nameCopy: function () {
      this.form.copyLabel =
        this.nameCopy + this.$i18n.t("vue_label_norm_duplicate");
    },
  },
  methods: {
    copyCustomCancel() {
      this.$emit("copyCustomCancel");
    },
    // 复制主页
    copyCustomConfirm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.$emit("copyCustomConfirm", this.form.copyLabel);
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/style/bouncedForm.scss";
.copyCustom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  background: rgba($color: #000000, $alpha: 0.4);
  min-height: 500px;
  overflow: auto;
  .copyCustom_center {
    width: 540px;
    background: white;
    position: relative;
    margin: 5% auto; /*水平居中*/
    top: 156px; /*偏移*/
    transform: translateY(-50% -50%);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
    border-radius: 6px;
    .center_header {
      padding: 17px 20px 15px 20px;
      display: flex;
      justify-content: space-between;
      .header_left {
        color: #080707;
        font-weight: bold;
      }
      .header_right {
        cursor: pointer;
        img {
          width: 15px;
          height: 15px;
        }
      }
    }
    .center_center {
      border-radius: 4px;
      color: #666666;
      .el-form {
        text-align: left;
        font-size: 13px;
        padding: 0 40px;
        ::v-deep .el-form-item {
          margin-top: 14px;
          margin-bottom: 12px;
          flex-direction: row;
          ::v-deep .el-form-item__label {
            width: 102px !important;
            color: #080707;
            font-size: 14px;
            padding: 8px 8px 0 0;
          }
          ::v-deep .el-form-item__content {
            line-height: 43px !important;
            .el-input {
              font-size: 12px;
              ::v-deep .el-input__inner {
                width: 300px;
              }
            }
          }
        }
      }
    }
    .center_footer {
      height: 60px;
      padding: 0 20px;
      border-radius: 0 0 8px 8px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      div {
        padding: 6px 12px;
        border-radius: 4px;
        font-size: 12px;
        cursor: pointer;
      }
      div:first-child {
        color: #5780b3;
        border: 1px solid #dcdcdc;
        background: #fff;
        margin-right: 10px;
      }
      div:last-child {
        color: #fff;
        background: #1b5297;
      }
    }
  }
}
</style>