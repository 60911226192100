<!--
  自定义主页
-->
<template>
  <div class="body">
    <el-card class="box-card" v-loading="isLoadingListStatus">
      <!-- 头部 -->
      <div class="custom_header">
        <div class="title">
          <!-- 自定义首页 -->
          <div>{{ $t("vue_label_homepage_custom_homepage") }}</div>
          <div>
            <svg class="icon" aria-hidden="true" @click="cloneCustom">
              <use href="#icon-chahao"></use>
            </svg>
          </div>
        </div>
        <div class="textText">
          <!-- 您可以为每个简档创建简档主页布局,从而使员工更容易有效地完成其日常任务 -->
          {{ $t("vue_label_homepage_notice2") }}
        </div>
        <div class="addNewCustom_div">
          <!-- 应用程序主页分配 -->
          <div @click="applicationHomePageOpen">
            {{ $t("vue_label_homepage_homepageassignment") }}
          </div>
          <div @click="pageLayoutAllocationOpen">
            <!-- 简档主页分配 -->
            {{ $t("vue_label_homepage_profilehomepageassignment") }}
          </div>
          <div @click="addNewCustomBounced">
            <svg aria-hidden="true">
              <use href="#icon-addZhuye"></use>
            </svg>
            <span>
              <!-- 新首页 -->
              {{ $t("vue_label_homepage_new_homepage_page") }}
            </span>
          </div>
        </div>
      </div>
      <!-- 内容-表格 -->
      <el-table
        :cell-style="{ background: '#fff' }"
        :data="tableData"
        style="width: 100%"
        height="calc(100vh - 240px)"
        :header-cell-style="{ background: '#F6F6F6', color: '#0E0D0D' }"
      >
        <!-- 主页名称 -->
        <el-table-column :label="$t('vue_label_homepage_name')" min-width="300">
          <template slot-scope="scope">
            <!-- 项目云标准主页不能编辑 -->
            <!-- 右击出现新的标签页 -->
            <!-- <div v-if="scope.row.id !== '20214152DFC0DEDW4LlP'"> -->
            <div>
              <a
                :href="`#/homePageObject/rolePage/?id=${scope.row.id}`"
                class="fristTd"
                target="_blank"
                @click.prevent="
                  $router.push(`/homePageObject/rolePage/?id=${scope.row.id}`)
                "
                >{{ scope.row.name }}</a
              >
              <!-- 名称弹框 -->
              <el-popover
                placement="right-start"
                trigger="click"
                width="270"
                popper-class="popo_operation"
                :ref="`nameRef-${scope.$index}`"
                @show="showName(scope.row)"
              >
                <div class="name-box">
                  <div class="editName">
                    <!-- 编辑主页名称 -->
                    {{ $t("vue_label_homepage_editname") }}
                  </div>
                  <el-form
                    :model="nameForm"
                    :rules="rules"
                    ref="nameFormArea"
                    label-width="66px"
                    class="demo-ruleForm"
                  >
                    <el-form-item label="NAME" prop="customEditName">
                      <!-- 请输入修改名称 -->
                      <el-input
                        v-model="nameForm.customEditName"
                        size="mini"
                        :placeholder="$t('vue_label_homepage_editname')"
                      ></el-input>
                    </el-form-item>
                  </el-form>
                  <div class="butts">
                    <el-button @click.native="nameCancel(scope)">
                      <!-- 取消 -->
                      {{ $t("button_partner_cancel") }}
                    </el-button>
                    <el-button type="primary" @click.native="nameSave(scope)">
                      <!-- 保存 -->
                      {{ $t("label.save") }}
                    </el-button>
                  </div>
                </div>
                <svg class="icon hoverImg" aria-hidden="true" slot="reference">
                  <use href="#icon-editPen-blue"></use>
                </svg>
              </el-popover>
            </div>
            <!-- <span v-if="scope.row.id === '20214152DFC0DEDW4LlP'">{{ -->
              <!-- <span>{{
              scope.row.name
            }}</span> -->
          </template>
        </el-table-column>
        <!-- 创建人 -->
        <el-table-column :label="$t('label.createby')" min-width="320">
          <template slot-scope="scope">
            <div>
              {{ scope.row.createname }}&nbsp;&nbsp;{{
                scope.row.createdate | formatTime(timeZone)
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 最后修改人 -->
        <el-table-column :label="$t('label.lastmodifyby')" min-width="320">
          <template slot-scope="scope">
            <div>
              {{ scope.row.lastmodifyname }}&nbsp;&nbsp;{{
                scope.row.lastmodifydate | formatTime(timeZone)
              }}
            </div>
          </template>
        </el-table-column>
        <!-- 操作 -->
        <el-table-column :label="$t('label.operate')">
          <template slot-scope="scope">
            <!-- v-if="scope.row.id !== '20214152DFC0DEDW4LlP'" -->
            <el-popover
              
              placement="bottom"
              trigger="hover"
              popper-class="popo_operation"
            >
              <ul id="popo-ul">
                <li @click="CustomEdit(scope.row.id)">
                  <!-- 编辑 -->
                  {{ $t("label.modify") }}
                </li>
                <li @click="CustomDel(scope.row)">
                  <!-- 删除 -->
                  {{ $t("button_partner_delete") }}
                </li>
                <!-- <li @click="CustomCopy(scope.row)">复制</li> -->
              </ul>
              <svg
                class="icon svg_operation"
                aria-hidden="true"
                slot="reference"
              >
                <use href="#icon-sanjiao"></use>
              </svg>
            </el-popover>
          </template>
        </el-table-column>
      </el-table>
    </el-card>
    <!-- 添加新的自定义首页弹框 -->
    <addNewCustom
      @addNewCustomCancel="addNewCustomCancel"
      @addNewCustomConfirm="addNewCustomConfirm"
      :addNewCustomTo="addNewCustomTo"
    ></addNewCustom>
    <!-- 简档主页分配弹框 -->
    <pageLayoutAllocation
      :pageLayoutAllocationTo="pageLayoutAllocationTo"
      :tableData="tableData"
      :pageLayoutData="pageLayoutData"
      :homeProfileList="homeProfileList"
      @cancleForm="cancleForm"
    ></pageLayoutAllocation>
    <!-- 应用程序主页分配弹框 -->
    <application-home-page
      :applicationHomePageTo="applicationHomePageTo"
      :tableData="tableData"
      :pageLayoutData="pageLayoutData"
      :homeProfileList="homeProfileList"
      @cancleApplicationHomePage="cancleApplicationHomePage"
      :applicationId="applicationId"
    />
    <!-- 删除主页弹框 -->
    <deleteCustom
      :deleteCustomTo="deleteCustomTo"
      @deleteCustomCancel="deleteCustomCancel"
      @deleteCustomConfirm="deleteCustomConfirm"
      :name="nameForm.customEditName"
    ></deleteCustom>
    <!-- 复制主页弹框 -->
    <copyCustom
      v-show="copyCustomTo"
      @copyCustomConfirm="copyCustomConfirm"
      :nameCopy="nameForm.customEditName"
      @copyCustomCancel="copyCustomCancel"
    ></copyCustom>
  </div>
</template>

<script>
import * as request from "./api.js";
import addNewCustom from "./components/addNewCustom";
import pageLayoutAllocation from "./components/pageLayoutAllocation";
import applicationHomePage from "./components/applicationHomePage";
import deleteCustom from "./components/deleteCustom";
import copyCustom from "./components/copyCustom";

export default {
  components: {
    addNewCustom,
    pageLayoutAllocation,
    deleteCustom,
    copyCustom,
    applicationHomePage,
  },
  created() {
    // 自定义主页title
    document.title = this.$setTitle(
      this.$i18n.t("vue_label_homepage_custom_homepage_page")
    );
    this.getProfileHomePageList();
  },
  data() {
    return {
      addNewCustomTo: false,
      pageLayoutAllocationTo: false,
      applicationHomePageTo: false, // 控制应用程序主页分配弹框显示隐藏
      deleteCustomTo: false,
      copyCustomTo: false,
      // value: true,
      nameForm: {
        customEditName: "",
      },
      deleteId: "",
      copyId: "",
      tableData: [],
      pageLayoutData: [],
      homeProfileList: [],
      rules: {
        customEditName: [
          {
            required: true,
            message: this.$i18n.t("vue_label_notice_homepage_pleaceentername"), //请输入主页名称
            trigger: "change",
          },
        ],
      },
      isLoadingListStatus: false, //列表加载中loading
      // 应用程序id
      applicationId: this.$store.state.home.homeApplicationId
        ? this.$store.state.home.homeApplicationId
        : "", //应用程序id
      // countryCode: $cookies.get("countryCode"),
    };
  },

  methods: {
    // 获取简档主页列表
    async getProfileHomePageList() {
      this.isLoadingListStatus = true;
      let res = await request.getProfileHomePageList();
      this.tableData = res.data;
      this.isLoadingListStatus = false;
    },
    // 监听主页名称的弹框显示
    showName(item) {
      this.nameForm.customEditName = item.name;
    },
    //保存按钮
    nameSave(scope) {
      this.$refs.nameFormArea.validate((valid) => {
        if (valid) {
          request
            .saveHomePage({
              id: scope.row.id,
              name: this.nameForm.customEditName,
              type: "profile",
            })
            .then(() => {
              this.$message.success(
                this.$i18n.t("vue_label_notice_modify_success")
              );
              this.tableData[scope.$index].name = this.nameForm.customEditName;
              scope._self.$refs[`nameRef-${scope.$index}`].doClose();
            });
        } else {
          return false;
        }
      });
    },
    //取消按钮
    nameCancel(scope) {
      scope._self.$refs[`nameRef-${scope.$index}`].doClose();
    },
    //点'X'路由跳转
    cloneCustom() {
      this.$router.push("/homePageObject/standardPage");
    },
    // 操作中的编辑
    CustomEdit(id) {
      this.$router.push({
        path: `/homePageObject/rolePage/?id=${id}`,
      });
    },
    // 操作中的删除
    CustomDel(item) {
      this.nameForm.customEditName = item.name;
      this.deleteCustomTo = true;
      this.deleteId = item.id;
    },
    //删除
    async deleteCustomConfirm() {
      let params = {
        id: this.deleteId,
      };
      await request.deleteHomePage(params);
      // 删除页面成功
      this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
      this.getProfileHomePageList();
      this.deleteCustomCancel();
    },
    //删除主页弹框取消
    deleteCustomCancel() {
      this.deleteCustomTo = false;
    },
    //复制
    async copyCustomConfirm(copyName) {
      let params = {
        id: this.copyId,
        name: copyName,
      };
      await [request.copyHomePage(params)];
      this.getProfileHomePageList();
      this.copyCustomCancel();
    },
    //复制主页弹框关闭
    copyCustomCancel() {
      this.copyCustomTo = false;
    },
    //添加新首页
    addNewCustomBounced() {
      this.addNewCustomTo = true;
    },
    //添加新首页框取消
    addNewCustomCancel() {
      this.addNewCustomTo = false;
    },
    // 保存主页基础信息, 新建简档主页保存
    async addNewCustomConfirm(name) {
      let res = await request.saveHomePage({
        name,
        type: "profile",
      });
      // 保存成功？
      if (res.result) {
        this.addNewCustomTo = false;
        // 新建简档主页成功！
        this.$message.success(this.$i18n.t("vue_label_notice_new_success"));
        this.$router.push({
          path: "/homePageObject/rolePage",
          query: {
            id: res.data.id,
          },
        });
      }
    },
    //简档主页分配弹框打开
    async pageLayoutAllocationOpen() {
      let res = await request.getProfileHomePageSetup();
      this.pageLayoutData = res.data.profileList;
      this.pageLayoutData.map((item) => {
        item.homePageId = "";
        // 设为默认主页
        item.checked = false;
        // 应用于移动端
        item.mobileChecked = false;
      });
      this.homeProfileList = res.data.homeProfileList;
      this.pageLayoutAllocationTo = true;
    },
    // 应用程序主页分配弹框打开
    async applicationHomePageOpen() {
      let res = await request.getProfileHomePageSetup();
      // 过滤空数据的了
      let appList = res.data.appList.filter((item) => item.applabel && item);
      this.pageLayoutData = appList;
      this.pageLayoutData.map((item) => {
        item.homePageId = "";
      });
      this.homeProfileList = res.data.homeProfileList;
      this.applicationHomePageTo = true;
    },
    //页面布局分配弹框关闭
    cancleForm() {
      this.pageLayoutAllocationTo = false;
    },
    // 应用程序分配主页弹框关闭
    cancleApplicationHomePage() {
      this.applicationHomePageTo = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.body {
  padding: 10px;
  height: 100%;
}
.box-card {
  height: 100%;
}
.fristTd {
  color: #1b5297;
  margin-right: 10px;
  cursor: pointer;
}
::v-deep .el-card__header {
  padding: 10px 10px;
}
::v-deep .el-card__body {
  padding: 10px;
  height: 100%;
  display: flex;
  flex-direction: column;
}
::v-deep .el-table::before {
  height: 0;
}

//头部样式
.custom_header {
  // padding: 10px 30px 10px 30px;
  padding-bottom: 30px;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
    color: #0e0d0d;
    font-size: 14px;
    font-weight: bold;
    svg {
      vertical-align: middle;
      margin-top: -6px;
      cursor: pointer;
    }
  }
  .textText {
    color: #0e0d0d;
    font-size: 12px;
    margin-left: 29px;
    padding-bottom: 30px;
  }
  .addNewCustom_div {
    display: flex;
    justify-content: flex-end;
    div:hover {
      cursor: pointer;
    }
    div:first-child,
    div:nth-child(2) {
      font-size: 12px;
      margin-right: 20px;
      text-align: center;
      padding: 6px 16px;
      border-radius: 4px;
      border: 1px solid #979797;
    }
    div:last-child {
      svg {
        margin-right: 4px;
        vertical-align: middle;
        margin-top: -4px;
        width: 14px;
        height: 14px;
      }
      background: #1b5297;
      color: white;
      font-size: 12px;
      margin-right: 26px;
      text-align: center;
      padding: 6px 16px;
      border-radius: 4px;
    }
  }
}

//表格样式
::v-deep .el-table {
  font-size: 12px;
  flex: 1;
  overflow: auto;
}
</style>

<style lang="scss">
.hoverImg {
  cursor: pointer;
  display: inline-block;
}
.el-popover.popo_operation {
  width: 70px;
  min-width: 0;
  padding: 0;
  #popo-ul {
    margin: 12px 0;
    li {
      padding: 5px 10px;
      font-size: 12px !important;
      color: #080707;
      cursor: pointer;
      &:hover {
        background: #006dcc !important;
        color: white !important;
      }
    }
  }
  .name-box {
    padding: 15px;
    width: 270px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .editName {
      color: #080707;
    }
    .demo-ruleForm {
      color: #080707;
      font-size: 12px;
      .el-form-item {
        margin-bottom: 0px !important;
      }
    }
    .butts {
      display: flex;
      justify-content: flex-end;
      .el-button {
        padding: 6px 8px;
        border-radius: 4px;
      }
      // ::v-deep .el-button--default {
      //   background-color: #fff;
      //   color: #302c2c;
      //   border-color: #dddbda;
      // }
      // primary
      // ::v-deep .el-button--primary {
      //   background-color: #409eff;
      //   border-color: #409eff;
      //   margin-right: 6.5px;
      // }
    }
  }
}
.svg_operation {
  width: 16px;
  height: 16px;
}
</style>
