<!--
  添加新的自定义首页弹框
-->

<template>
<!-- 新建简档主页 -->
  <el-dialog :visible="addNewCustomTo"
             :title="$t('vue_label_homepage_new_homepage')"
             :before-close="handleClose"
             :close-on-click-modal="false">
    <div class="dialog-body">
      <el-form :model="form"
               :rules="rules"
               ref="form">
               <!-- 简档主页名称 -->
        <el-form-item :label="$t('label_chatter_name')"
                      prop="name"
                      :label-width="formLabelWidth">
          <el-input v-model="form.name"
                    auto-complete="off"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer"
          class="dialog-footer">
      <el-button @click="addNewCustomCancel">
        <!-- 取消 -->
       {{ $t("button_partner_cancel") }}        
        </el-button>
      <el-button type="primary" @click="addNewCustomConfirm('form')">
        <!-- 确定 -->
        {{ $t("label.ems.confirm") }}
        </el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    addNewCustomTo: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      form: {
        name: "",
      },
      formLabelWidth: "100px",
      rules: {
        // 请输入简档主页名称
        name: [
          { required: true, message: this.$i18n.t('vue_label_homepage_enter_name'), trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    handleClose () {
      this.$emit("addNewCustomCancel");
    },
    addNewCustomCancel () {
      this.$emit("addNewCustomCancel");
    },
    addNewCustomConfirm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$emit("addNewCustomConfirm", this.form.name);
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog {
  width: 552px;
}
.dialog-body {
  border-radius: 4px;
  color: #666666;
  .el-form {
    text-align: left;
    font-size: 13px;
    padding: 0 40px;
    ::v-deep .el-form-item {
      margin-top: 14px;
      margin-bottom: 12px;
      ::v-deep .el-form-item__label {
        width: 104px !important;
        color: #080707;
        font-size: 14px;
        padding: 0 8px 0 0;
      }
      ::v-deep .el-form-item__content {
        margin-left: 102px !important;
        line-height: 43px !important;
        .el-input {
          width: 95%;
          font-size: 12px;
        }
      }
    }
  }
}
</style>